<template>
  <div :class="iconClass">
    <VueInlineSvg
      :src="require(`@/assets/${name}.svg`)"
      class="svg-icon"
      :class="iconClass"
      :style="cssProps"
    />
  </div>
</template>

<script>
import VueInlineSvg from "vue-inline-svg";

export default{
  name: 'InlineSvg',
  components: {
    VueInlineSvg,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    }
  },
  computed: {
    cssProps(){
      return {
        '--icon-color': (this.color),
      }
    },
    iconClass(){
      return 'icon-' + (this.name);
    }
  }
}
</script>

<style lang="less">
svg{
  width:100%;
  height: auto;
  fill: var(--icon-color);
}
</style>
